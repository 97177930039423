import React from 'react'
import {
    Block,
    Button,
    ButtonVariants,
    Card,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import BuildSite from '../components/Illustration/BuildSite'
import { PRODUCTS } from '../constants/products.const'
import { CONTACT_EMAIL } from '../constants/contact.const'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <>
            <section className='hero'>
                <Block variant={ThemeVariants.MAIN}>
                    <div className='container rds-p_top rds-p_bottom'>
                        <div className='rds-flexbox align-center justify-between'>
                            <header>
                                <Typography
                                    type={TypographyTypes.H1}
                                    variant={ThemeVariants.WHITE}
                                    style={{ fontSize: 45, lineHeight: 1.2 }}
                                >
                                    Tu tienda online
                                    <br />
                                    como siempre la imaginaste
                                </Typography>
                                <br />
                                <Typography
                                    type={TypographyTypes.H3}
                                    variant={ThemeVariants.WHITE}
                                    style={{
                                        fontSize: 24,
                                        lineHeight: 1.5,
                                        maxWidth: 500,
                                        opacity: 0.8,
                                    }}
                                >
                                    Crea tu tienda online en minutos y comienza
                                    a vender tus productos en todo el país.
                                </Typography>
                                <Link to='https://admin.reango.app/register'>
                                    <Button
                                        className='rds-m_top__md'
                                        label='Crear tu proyecto'
                                        variant={ButtonVariants.SECONDARY}
                                    />
                                </Link>
                            </header>
                            <BuildSite />
                        </div>
                    </div>
                </Block>
            </section>
            <section id='customizable'>
                <div className='container-sm rds-m_top'>
                    <Card>
                        <div>
                            <header>
                                <Typography
                                    variant={ThemeVariants.MAIN}
                                    type={TypographyTypes.H2}
                                    style={{ fontSize: 35, lineHeight: 1.2 }}
                                >
                                    Totalmente
                                    <br />
                                    personalizable.
                                </Typography>
                                <Typography
                                    className='rds-m_top__sm'
                                    style={{ fontSize: 20 }}
                                >
                                    Haz que tu tienda se vea como tú quieres.
                                </Typography>
                            </header>
                        </div>
                    </Card>
                </div>
            </section>
            <section id='products' className='rds-m_top'>
                <div className='container'>
                    <header className='rds-text-align_center'>
                        <Typography
                            type={TypographyTypes.H2}
                            style={{ opacity: 0.4 }}
                        >
                            Productos
                        </Typography>
                    </header>
                    <div className='rds-m_top__md rds-grid rds-grid_3-columns'>
                        {PRODUCTS.map((product, index) => (
                            <Card variant={ThemeVariants.GLOSSY} key={index}>
                                <header
                                    style={{ height: '100%' }}
                                    className='rds-flexbox direction-column justify-between'
                                >
                                    <Typography
                                        type={TypographyTypes.H3}
                                        variant={ThemeVariants.MAIN}
                                        style={{
                                            fontSize: 30,
                                            lineHeight: 1.2,
                                        }}
                                    >
                                        {product.title}
                                    </Typography>
                                    <Typography
                                        variant={ThemeVariants.MAIN}
                                        className='rds-m_top__md'
                                        style={{
                                            opacity: 0.8,
                                            fontWeight: 400,
                                            fontSize: 20,
                                        }}
                                    >
                                        {product.description}
                                    </Typography>
                                    <Link to={`/products/${product.slug}`}>
                                        <Typography
                                            variant={ThemeVariants.MAIN}
                                            className='rds-m_top__md'
                                        >
                                            <b className='rds-text-variant_link'>
                                                Conocer más
                                            </b>
                                        </Typography>
                                    </Link>
                                </header>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
            <section id='checkout' className='rds-m_top'>
                <div className='container-sm'>
                    <header className='rds-text-align_center'>
                        <Typography
                            type={TypographyTypes.H2}
                            style={{ opacity: 0.4 }}
                        >
                            Pagos online
                        </Typography>
                    </header>
                    <Card
                        className='rds-m_top__md'
                        style={{ paddingBottom: 300 }}
                    >
                        <header
                            className='rds-relative rds-p_right__md'
                            style={{ maxWidth: '55%', zIndex: 1 }}
                        >
                            <Typography
                                type={TypographyTypes.H2}
                                variant={ThemeVariants.MAIN}
                                style={{ fontSize: 35, lineHeight: 1.2 }}
                            >
                                Acepta pagos
                                <br />
                                de cualquier tarjeta.
                            </Typography>
                            <Typography
                                className='rds-m_top__sm'
                                style={{ fontSize: 20 }}
                            >
                                Con nuestro checkout integrado de Mercado Pago,
                                puedes ofrecer cualquier medio de pago
                                disponible en el país.
                            </Typography>
                        </header>
                        <img
                            className='rds-absolute'
                            alt='Mercado Pago Checkout Pro'
                            src='/mercadopago/checkout-pro-mobile.png'
                            width={600}
                            style={{
                                maxWidth: 'calc(45% + 30%)',
                                bottom: '-15%',
                                right: '-20%',
                            }}
                        />
                    </Card>
                </div>
            </section>
            <section id='contact' className='rds-m_top'>
                <Block
                    className='rds-p_top rds-p_bottom'
                    variant={ThemeVariants.MAIN}
                >
                    <div className='container'>
                        <div className='rds-flexbox direction-column align-center justify-between'>
                            <header className='rds-text-align_center'>
                                <Typography
                                    type={TypographyTypes.H2}
                                    variant={ThemeVariants.WHITE}
                                    style={{ fontSize: 35, lineHeight: 1.2 }}
                                >
                                    Comienza a crear tu tienda online ya
                                </Typography>
                                <Typography
                                    className='rds-m_top__sm'
                                    variant={ThemeVariants.WHITE}
                                    style={{ fontSize: 20 }}
                                >
                                    Contactate con nosotros y pondremos tu
                                    proyecto en marcha.
                                </Typography>
                            </header>
                            <div className='rds-flexbox direction-column align-center justify-between rds-m_top__md'>
                                <Link
                                    to={`mailto:${CONTACT_EMAIL}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Button label='Quiero contactarme' />
                                </Link>
                                <Typography
                                    className='rds-m_top__sm'
                                    variant={ThemeVariants.WHITE}
                                >
                                    o envia un mail a <b>{CONTACT_EMAIL}</b>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Block>
            </section>
        </>
    )
}

export default Home
