import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ScrollToTop from './layout/ScrollToTop'
import Topbar from './layout/Topbar'
import Footer from './layout/Footer'
import Home from './pages/Home'
import Pricing from './pages/Pricing'
import AdminPanel from './pages/AdminPanel'

function App() {
    return (
        <div>
            <Router>
                <ScrollToTop />
                <Topbar />
                <Routes>
                    <Route path='/pricing' Component={Pricing} />
                    <Route
                        path='/products/admin-panel'
                        Component={AdminPanel}
                    />
                    <Route path='*' Component={Home} />
                </Routes>
                <Footer />
            </Router>
        </div>
    )
}

export default App
