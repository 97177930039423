import React from 'react'
import {
    Block,
    Card,
    Icon,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import { PRODUCTS } from '../constants/products.const'
import Illustration from '../components/Illustration/AdminPanel'

const AdminPanel = () => {
    const product = PRODUCTS.find(product => product.slug === 'admin-panel')
    return (
        <>
            <section id='admin-panel' className='rds-m_top'>
                <div className='container rds-flexbox align-center justify-between'>
                    <header>
                        <Typography
                            variant={ThemeVariants.MAIN}
                            type={TypographyTypes.H2}
                            style={{ fontSize: 45, lineHeight: 1.2 }}
                        >
                            Panel
                            <br />
                            de Administración
                        </Typography>
                        <Typography
                            className='rds-m_top__sm'
                            style={{ fontSize: 20 }}
                        >
                            {product.description}
                        </Typography>
                    </header>
                    <Illustration />
                </div>
            </section>
            <section className='rds-m_top rds-m_bottom container-sm'>
                <Card>
                    <header>
                        <Typography
                            className='rds-text-align_center'
                            style={{ fontSize: 18 }}
                        >
                            Desde el {product.title}, puedes gestionar todo lo
                            relacionado a tu proyecto, desde tus ventas, hasta
                            la manera en la que tus clientes ven y utilizan tu
                            tienda online.
                        </Typography>
                    </header>
                </Card>
            </section>
            <section className='rds-m_top rds-m_bottom'>
                <div className='container'>
                    {product.features.map((feature, index) => (
                        <article
                            key={index}
                            className='rds-relative rds-m_top rds-m_bottom'
                        >
                            <div
                                className='rds-relative rds-flexbox align-center justify-between'
                                style={
                                    index / 2 === 0
                                        ? { flexDirection: 'row-reverse' }
                                        : {}
                                }
                            >
                                <header className='rds-p_around__md'>
                                    <Typography type={TypographyTypes.H2}>
                                        {feature.title}
                                    </Typography>
                                    <ul className='rds-m_top__sm'>
                                        {feature.highlights.map(
                                            (highlight, index) => (
                                                <li
                                                    key={index}
                                                    className='rds-flexbox align-center'
                                                >
                                                    <Icon
                                                        variant={
                                                            ThemeVariants.SECONDARY
                                                        }
                                                        className='rds-m_right__sm'
                                                        type='Check'
                                                        size={10}
                                                    />
                                                    <Typography
                                                        style={{ fontSize: 16 }}
                                                    >
                                                        {highlight}
                                                    </Typography>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                </header>
                                <Block
                                    className='rds-m_top rds-overflow_hidden'
                                    style={{ width: 750, height: 400 }}
                                    bordered
                                    roundedBorder
                                >
                                    <img
                                        src={feature.preview}
                                        alt='Ventas'
                                        width={750}
                                        height={400}
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top left',
                                        }}
                                    />
                                </Block>
                            </div>
                        </article>
                    ))}
                </div>
            </section>
        </>
    )
}

export default AdminPanel
