import React from 'react'
import {
    Block,
    Button,
    Card,
    Icon,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import { PLANS, PLUS_PLAN_FEATURES } from '../constants/pricing.const'
import { Link } from 'react-router-dom'

const Pricing = () => {
    return (
        <>
            <section className='pricing rds-m_top rds-m_bottom'>
                <div className='container'>
                    <header className='rds-text-align_center'>
                        <Typography
                            type={TypographyTypes.H1}
                            variant={ThemeVariants.MAIN}
                        >
                            Planes
                        </Typography>
                        <Typography variant={ThemeVariants.MAIN}>
                            Planes hechos para tu proyecto
                        </Typography>
                    </header>
                    <div className='container-sm rds-m_top rds-grid rds-grid_2-columns'>
                        {PLANS.map((subscription, index) => (
                            <Card
                                className='rds-flexbox direction-column justify-between'
                                key={index}
                            >
                                <header>
                                    <Typography type={TypographyTypes.H2}>
                                        {subscription.name}
                                    </Typography>
                                    <Typography>
                                        {subscription.description}
                                    </Typography>
                                </header>
                                <ul className='rds-m_top__md'>
                                    {PLUS_PLAN_FEATURES.map(
                                        (feature, index) => {
                                            const isIncluded =
                                                subscription.features.includes(
                                                    feature,
                                                )
                                            return (
                                                <li
                                                    style={
                                                        isIncluded
                                                            ? null
                                                            : {
                                                                  opacity: 0.5,
                                                              }
                                                    }
                                                    key={index}
                                                >
                                                    <Typography>
                                                        <Icon
                                                            type={
                                                                isIncluded
                                                                    ? 'Check'
                                                                    : 'Close'
                                                            }
                                                            className='rds-m_right__sm'
                                                            variant={
                                                                isIncluded
                                                                    ? ThemeVariants.MAIN
                                                                    : null
                                                            }
                                                        />{' '}
                                                        {feature}
                                                    </Typography>
                                                </li>
                                            )
                                        },
                                    )}
                                </ul>
                                <div className='rds-m_top__md'>
                                    <Typography
                                        type={TypographyTypes.H3}
                                        style={{ fontSize: 20 }}
                                    >
                                        {subscription.price} USD/mes
                                    </Typography>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
            <section className='trial rds-m_top'>
                <Block
                    className='rds-p_top rds-p_bottom'
                    variant={ThemeVariants.MAIN}
                >
                    <div className='container'>
                        <div className='rds-flexbox direction-column align-center justify-between'>
                            <header className='rds-text-align_center'>
                                <Typography
                                    type={TypographyTypes.H2}
                                    variant={ThemeVariants.WHITE}
                                    style={{ fontSize: 35, lineHeight: 1.2 }}
                                >
                                    Registrate ahora gratis
                                </Typography>
                                <Typography
                                    className='rds-m_top__sm'
                                    variant={ThemeVariants.WHITE}
                                    style={{ fontSize: 20 }}
                                >
                                    Crea una cuenta sin necesidad de tarjeta de
                                    crédito, paga solamente cuando estés listo!
                                </Typography>
                            </header>
                            <div className='rds-flexbox direction-column align-center justify-between rds-m_top__md'>
                                <Link to='https://admin.reango.app/register'>
                                    <Button label='Quiero registrarme' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Block>
            </section>
        </>
    )
}

export default Pricing
