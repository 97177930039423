import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Button, ButtonVariants, Card, Logo, Typography } from 'rds'

const getNavLinkClassName = ({ isActive }) => {
    return isActive ? 'active' : ''
}

const Topbar = () => {
    return (
        <Card className='topbar'>
            <div className='container'>
                <div className='rds-flexbox align-center justify-between'>
                    <div className='rds-flexbox align-center'>
                        <Link to='/'>
                            <Logo />
                        </Link>
                        <ul className='rds-flexbox rds-m_left'>
                            <li className='rds-m_right__md'>
                                <NavLink to='/' className={getNavLinkClassName}>
                                    <Typography>Inicio</Typography>
                                </NavLink>
                            </li>
                            <li className='rds-m_right__md'>
                                <NavLink
                                    to='/pricing'
                                    className={getNavLinkClassName}
                                >
                                    <Typography>Planes</Typography>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='rds-flexbox align-center'>
                        <Link to='https://admin.reango.app/'>
                            <Button
                                label='Iniciar sesión'
                                className='rds-m_right__sm'
                                variant={ButtonVariants.TRANSPARENT}
                            />
                        </Link>
                        <Link to='https://admin.reango.app/register'>
                            <Button
                                label='Registrarse gratis'
                                variant={ButtonVariants.MAIN}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default Topbar
