import React from 'react'
import { Link } from 'react-router-dom'
import { Block, Logo, ThemeVariants, Typography, TypographyTypes } from 'rds'

const Footer = () => {
    return (
        <footer>
            <Block variant={ThemeVariants.GRAY}>
                <div className='container rds-p_top rds-p_bottom'>
                    <div className='rds-grid rds-grid_3-columns'>
                        <div>
                            <Logo />
                            <Typography className='rds-m_top__sm'>
                                Montevideo, Uruguay
                            </Typography>
                        </div>
                        <section id='links'>
                            <Typography type={TypographyTypes.H4}>
                                Enlaces
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className='rds-m_top__sm'>
                                        <Link to='/'>Inicio</Link>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className='rds-m_top__sm'>
                                        <Link to='/pricing'>Planes</Link>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className='rds-m_top__sm'>
                                        <Link to='https://docs.reango.app/'>
                                            Documentación
                                        </Link>
                                    </Typography>
                                </li>
                            </ul>
                        </section>
                        <section id='developers'>
                            <Typography type={TypographyTypes.H4}>
                                Desarrolladores
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className='rds-m_top__sm'>
                                        <Link to='https://design.reango.app/'>
                                            Design System
                                        </Link>
                                    </Typography>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
                <Block
                    style={{ backgroundColor: '#00000010' }}
                    className='rds-text-align_center rds-p_top__sm rds-p_bottom__sm'
                >
                    <Typography>
                        2024. Todos los derechos reservados.
                    </Typography>
                </Block>
            </Block>
        </footer>
    )
}

export default Footer
