import React from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
    const { pathname } = useLocation()

    React.useEffect(() => {
        const body = document.querySelector('body')
        body.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
        })
    }, [pathname])

    return null
}
