import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { cssRules } from 'rds'

const BuildSite = ({ style, width = 500 }) => {
    const themeContext = useContext(ThemeContext)
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 400 300'
            width={width}
            style={style}
            className='illustration styles_illustrationTablet__1DWOa'
        >
            <g id='_003' data-name='003'>
                <path
                    d='M356.5,231.33H43.5a.46.46,0,0,1-.46-.46.47.47,0,0,1,.46-.46h313a.47.47,0,0,1,.46.46A.46.46,0,0,1,356.5,231.33Z'
                    fill='#d1d3d4'
                ></path>
                <rect
                    x='66.93'
                    y='44.49'
                    width='272.15'
                    height='166.33'
                    rx='5.37'
                    fill='#fff'
                ></rect>
                <path
                    d='M333.7,211.32H72.3a5.88,5.88,0,0,1-5.87-5.88V49.86A5.87,5.87,0,0,1,72.3,44H333.7a5.87,5.87,0,0,1,5.87,5.87V205.44A5.88,5.88,0,0,1,333.7,211.32ZM72.3,45a4.87,4.87,0,0,0-4.87,4.87V205.44a4.88,4.88,0,0,0,4.87,4.88H333.7a4.88,4.88,0,0,0,4.87-4.88V49.86A4.87,4.87,0,0,0,333.7,45Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M187.75,163.67H82.63a.46.46,0,0,1-.46-.46.47.47,0,0,1,.46-.46H187.75a.47.47,0,0,1,.46.46A.46.46,0,0,1,187.75,163.67Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M139.32,202.34a.46.46,0,0,1-.46-.46V163a.46.46,0,0,1,.46-.46.47.47,0,0,1,.46.46v38.86A.47.47,0,0,1,139.32,202.34Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.64,137.77H199.76a.46.46,0,0,1-.46-.46.45.45,0,0,1,.46-.46H320.64a.45.45,0,0,1,.46.46A.46.46,0,0,1,320.64,137.77Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.64,149.59H199.76a.45.45,0,0,1-.46-.46.46.46,0,0,1,.46-.46H320.64a.46.46,0,0,1,.46.46A.45.45,0,0,1,320.64,149.59Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.64,161.42H199.76a.46.46,0,0,1,0-.92H320.64a.46.46,0,1,1,0,.92Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.64,173.25H199.76a.47.47,0,0,1,0-.93H320.64a.47.47,0,0,1,0,.93Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.64,185.07H199.76a.46.46,0,0,1,0-.92H320.64a.46.46,0,1,1,0,.92Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M326.84,121.81H187.18a.46.46,0,0,1-.46-.46.47.47,0,0,1,.46-.46H326.84a.47.47,0,0,1,.46.46A.46.46,0,0,1,326.84,121.81Z'
                    fill='#d1d3d4'
                ></path>
                <rect
                    x='199.38'
                    y='77.99'
                    width='48.05'
                    height='32.66'
                    rx='2.08'
                    fill={themeContext.secondary}
                ></rect>
                <path
                    d='M187.56,201.4a.45.45,0,0,1-.46-.46V68.56a.46.46,0,0,1,.46-.46.47.47,0,0,1,.46.46V200.94A.46.46,0,0,1,187.56,201.4Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M327.77,68.69h-250a.46.46,0,0,1-.46-.46.47.47,0,0,1,.46-.46h250a.47.47,0,0,1,.47.46A.46.46,0,0,1,327.77,68.69Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M322.62,202.68H83.38a5.88,5.88,0,0,1-5.88-5.87V58.5a5.89,5.89,0,0,1,5.88-5.88H322.62a5.89,5.89,0,0,1,5.88,5.88V196.81A5.88,5.88,0,0,1,322.62,202.68ZM83.38,53.62A4.89,4.89,0,0,0,78.5,58.5V196.81a4.88,4.88,0,0,0,4.88,4.87H322.62a4.88,4.88,0,0,0,4.88-4.87V58.5a4.89,4.89,0,0,0-4.88-4.88Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M230.7,210.87,202,210.8l-28.69.07s-2.53,26-19,37.13h95.41C233.23,236.9,230.7,210.87,230.7,210.87Z'
                    fill='#fff'
                ></path>
                <path
                    d='M249.71,248.5H154.29a.49.49,0,0,1-.47-.35.48.48,0,0,1,.2-.56c16.09-10.84,18.76-36.51,18.79-36.77a.5.5,0,0,1,.5-.45L202,210.3l28.7.07a.51.51,0,0,1,.5.45c0,.26,2.7,25.93,18.79,36.77a.51.51,0,0,1,.2.56A.5.5,0,0,1,249.71,248.5Zm-93.85-1h92.29c-14.2-10.69-17.47-32.71-17.89-36.13L202,211.3l-28.25.07C173.32,214.79,170.06,236.81,155.86,247.5Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <rect
                    x='142.21'
                    y='248'
                    width='119.59'
                    height='9.9'
                    rx='4.86'
                    fill='#fff'
                ></rect>
                <path
                    d='M256.94,258.41H147.07a5.37,5.37,0,0,1-5.36-5.36v-.19a5.37,5.37,0,0,1,5.36-5.36H256.94a5.37,5.37,0,0,1,5.36,5.36v.19A5.37,5.37,0,0,1,256.94,258.41ZM147.07,248.5a4.37,4.37,0,0,0-4.36,4.36v.19a4.37,4.37,0,0,0,4.36,4.36H256.94a4.37,4.37,0,0,0,4.36-4.36v-.19a4.37,4.37,0,0,0-4.36-4.36Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <circle
                    cx='93.67'
                    cy='61.38'
                    r='3.13'
                    fill={themeContext.secondary}
                ></circle>
                <circle
                    cx='105.9'
                    cy='61.38'
                    r='3.13'
                    fill={cssRules.blackValue(themeContext)}
                ></circle>
                <circle
                    cx='118.14'
                    cy='61.38'
                    r='3.13'
                    fill={cssRules.blackValue(themeContext)}
                ></circle>
                <path
                    d='M99.39,224.43l8.16,29.95s14.32,3.33,14,4.25S99,262.56,99,262.56L89.55,228'
                    fill='#fff'
                ></path>
                <path
                    d='M99,263.06a.5.5,0,0,1-.48-.37l-9.45-34.6a.49.49,0,0,1,.35-.61.5.5,0,0,1,.61.35L99.37,262c7.34-1,18.47-2.67,21.25-3.47a115,115,0,0,0-13.18-3.66.5.5,0,0,1-.37-.35l-8.16-30a.5.5,0,0,1,1-.26L108,254c5.12,1.2,13.44,3.28,14,4.33a.79.79,0,0,1,.05.5c-.12.36-.41,1.22-23,4.26Zm22.14-4.24h0Zm.06-.52Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M74,214.46l-2.35,45.4s22.37,4.85,22.45,4.27-12-9.21-12-9.21l3.51-32.26'
                    fill='#fff'
                ></path>
                <path
                    d='M93.9,264.64c-1.1,0-5.24-.57-22.4-4.29a.51.51,0,0,1-.4-.52l2.35-45.39a.5.5,0,1,1,1,.05l-2.33,45c6.92,1.5,17.27,3.64,20.7,4.12-2-1.71-7.51-5.69-11.11-8.25a.5.5,0,0,1-.2-.46L85,222.61a.5.5,0,0,1,.55-.45.51.51,0,0,1,.45.56l-3.48,32c12.15,8.64,12.07,9.15,12,9.52a.57.57,0,0,1-.22.35S94.21,264.64,93.9,264.64Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M112.28,254.11l-3,4.6L97.48,260.1l1.16,4.52s26-3.25,26.55-5.62S112.28,254.11,112.28,254.11Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M69.52,260.9l.68-4.64L83.86,260l2.66-3.51s10,7.26,8.64,8.82S79,264.05,69.52,260.9Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M64.94,103.61a5.66,5.66,0,0,1,4,.3A12.36,12.36,0,0,1,78,96.6a26.74,26.74,0,0,1,4.2-.35c2.1,0,4.36.13,6,1.42a6.77,6.77,0,0,1,2,6.87c-1.22,5-5,6.23-8.43,9.06a15.62,15.62,0,0,0-5.31,11.7c0,9.86,3.12,20.76-7.69,26.68-6.89,3.76-16.23.85-20.85-5.5a13.53,13.53,0,0,1-2.26-4.6c-1.13-4.49.83-9.19,3.29-13.11s5-7.47,7.06-11.49c1.78-3.5,2-7.72,4.58-10.86A8.45,8.45,0,0,1,64.94,103.61Z'
                    fill='#fff'
                ></path>
                <path
                    d='M62.05,154.15a18.17,18.17,0,0,1-14.45-7.38A14.07,14.07,0,0,1,45.26,142c-1-3.91.11-8.33,3.35-13.5.71-1.13,1.44-2.24,2.16-3.35a87.83,87.83,0,0,0,4.88-8.1,28.61,28.61,0,0,0,1.62-4.34,18.94,18.94,0,0,1,3-6.61,9,9,0,0,1,4.52-3h0a6.28,6.28,0,0,1,3.94.13A12.75,12.75,0,0,1,78,96.11a27.73,27.73,0,0,1,4.28-.36c2.12,0,4.52.12,6.34,1.52a7.24,7.24,0,0,1,2.21,7.39c-1,4-3.44,5.67-6,7.46-.84.58-1.71,1.18-2.55,1.86a15.09,15.09,0,0,0-5.13,11.31c0,1.79.11,3.65.21,5.45.47,8.2,1,16.69-8.16,21.67A14.61,14.61,0,0,1,62.05,154.15Zm3-50.06a8,8,0,0,0-4,2.64A18,18,0,0,0,58.22,113a28.37,28.37,0,0,1-1.68,4.5,88,88,0,0,1-4.93,8.19c-.72,1.11-1.45,2.22-2.15,3.34-3.09,4.93-4.14,9.09-3.23,12.73a13.07,13.07,0,0,0,2.18,4.42c4.71,6.48,13.78,8.88,20.21,5.36,8.56-4.69,8.12-12.49,7.64-20.75-.1-1.81-.21-3.68-.21-5.49a16.09,16.09,0,0,1,5.5-12.09c.87-.71,1.75-1.32,2.61-1.91,2.56-1.76,4.77-3.28,5.64-6.87.51-2.07.13-4.84-1.84-6.37-1.57-1.21-3.78-1.34-5.71-1.31a24.47,24.47,0,0,0-4.12.35,11.81,11.81,0,0,0-8.68,7,.53.53,0,0,1-.29.26.58.58,0,0,1-.39,0,5.18,5.18,0,0,0-3.7-.27Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M97.62,136.42c-2.22.85-5.87,1.64-6.82-1.44a4.75,4.75,0,0,1-.14-1.68,17.16,17.16,0,0,1,.45-2.46,42.11,42.11,0,0,1,2.34-6.6c.23-.54.69-1.19,1.25-1s.54.78.53,1.28c0,1.39-.23,2.8-.2,4.19,2.94-.75,4.79-3.86,7.59-4.53.79-.18,1.75-.24,1.84.78.1,1.26-2.18,2.25-2.94,2.9a9.2,9.2,0,0,1,2.89-1.68,1.9,1.9,0,0,1,.94-.15.75.75,0,0,1,.64.64,1,1,0,0,1-.34.69,10.16,10.16,0,0,1-1.44,1.34,3.62,3.62,0,0,1,2-1.21,1,1,0,0,1,1.11.31c.33.53-.3,1.13-.84,1.44a3.18,3.18,0,0,1,1.52-.76,1.12,1.12,0,0,1,1.28.86,1.55,1.55,0,0,1-.6,1.15C105.82,133.34,101.34,135,97.62,136.42Z'
                    fill='#fff'
                ></path>
                <path
                    d='M94.07,137.68a4.53,4.53,0,0,1-2-.4,3.37,3.37,0,0,1-1.76-2.15,5,5,0,0,1-.17-1.87,17.51,17.51,0,0,1,.46-2.54A42.31,42.31,0,0,1,93,124c.44-1,1.15-1.52,1.87-1.27.41.14.9.56.87,1.76,0,.6,0,1.2-.1,1.8s-.08,1.13-.1,1.69a12,12,0,0,0,3-2,10.36,10.36,0,0,1,3.92-2.31,2.38,2.38,0,0,1,1.91.15,1.39,1.39,0,0,1,.54,1.07,1.62,1.62,0,0,1-.07.62,1.75,1.75,0,0,1,.56,0,1.25,1.25,0,0,1,1,1.1,1.58,1.58,0,0,1,0,.32,1.36,1.36,0,0,1,1.33.58,1.07,1.07,0,0,1,.16.44h0a1.83,1.83,0,0,1,1.32.39,1.37,1.37,0,0,1,.51.93,2,2,0,0,1-.75,1.54c-2.84,2.8-7.06,4.42-10.77,5.85l-.5.2h0A11.23,11.23,0,0,1,94.07,137.68Zm.43-14c-.16,0-.41.31-.59.72A41.75,41.75,0,0,0,91.59,131a16.18,16.18,0,0,0-.43,2.39,4.37,4.37,0,0,0,.12,1.5,2.41,2.41,0,0,0,1.24,1.54c1.47.71,3.71,0,4.92-.43l.5-.19c3.62-1.4,7.73-3,10.43-5.63.2-.2.47-.5.45-.74a.38.38,0,0,0-.14-.25.86.86,0,0,0-.58-.17,1.94,1.94,0,0,0-.82.34,3.09,3.09,0,0,1-.51.36.51.51,0,0,1-.67-.16.5.5,0,0,1,.12-.67l.45-.33a.86.86,0,0,0,.28-.37c-.06-.17-.3-.22-.61-.16a3.16,3.16,0,0,0-.94.36,10.8,10.8,0,0,1-.89.76.5.5,0,0,1-.69-.72,4.31,4.31,0,0,1,1-.84c.17-.16.33-.33.49-.51s.21-.28.21-.34-.1-.14-.23-.16a1.46,1.46,0,0,0-.68.12,8.79,8.79,0,0,0-1.08.47c-.29.21-.59.4-.87.58a7.68,7.68,0,0,0-.78.54h0a.5.5,0,1,1-.66-.75h0a9.87,9.87,0,0,1,1.75-1.2c.56-.41,1-.87,1-1.28,0-.25-.11-.32-.14-.34s-.37-.16-1.08,0a9.54,9.54,0,0,0-3.53,2.12,10.87,10.87,0,0,1-4,2.4.5.5,0,0,1-.43-.08.53.53,0,0,1-.2-.39c0-.83,0-1.66.1-2.47.05-.58.09-1.16.1-1.74s-.09-.76-.2-.79Zm3.12,12.71h0Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M95.17,128.84a4.63,4.63,0,0,1,.09,5.79l.29,0'
                    fill='#fff'
                ></path>
                <path
                    d='M95.26,135.13a.51.51,0,0,1-.45-.28.51.51,0,0,1,.06-.54,4.12,4.12,0,0,0-.08-5.14.5.5,0,0,1,.76-.65,5.11,5.11,0,0,1,.46,5.91.83.83,0,0,1,0,.15.5.5,0,0,1-.47.53l-.29,0Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <rect
                    x='95.77'
                    y='96.18'
                    width='73.19'
                    height='34.73'
                    rx='3.34'
                    fill='#fff'
                ></rect>
                <path
                    d='M165.62,131.41H99.11a3.84,3.84,0,0,1-3.84-3.84v-28a3.84,3.84,0,0,1,3.84-3.84h66.51a3.84,3.84,0,0,1,3.84,3.84v28.05A3.84,3.84,0,0,1,165.62,131.41ZM99.11,96.68a2.84,2.84,0,0,0-2.84,2.84v28.05a2.84,2.84,0,0,0,2.84,2.84h66.51a2.84,2.84,0,0,0,2.84-2.84v-28a2.84,2.84,0,0,0-2.84-2.84Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <circle
                    cx='112.64'
                    cy='113.55'
                    r='9.29'
                    fill={cssRules.blackValue(themeContext)}
                ></circle>
                <path
                    d='M160.94,109.12H131.43a.5.5,0,0,1,0-1h29.51a.5.5,0,0,1,0,1Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M160.94,114.05H131.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h29.51a.5.5,0,0,1,.5.5A.5.5,0,0,1,160.94,114.05Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M150.69,119H131.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h19.26a.5.5,0,0,1,.5.5A.5.5,0,0,1,150.69,119Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M97,136.06s11.88,7.92,16.5,5.54,14.13-10.82,14.13-10.82,2.25-5.55,4.23-5.16.13,5.68.13,5.68,11.75-2,11.62-.79-6.34,4.09-9.11,4.62S121.72,151.51,115,153.36s-13.47-2.25-20.74-6.74'
                    fill='#fff'
                ></path>
                <path
                    d='M111.54,154.32c-5.79,0-11.53-3.55-17.55-7.27a.49.49,0,0,1-.16-.69.48.48,0,0,1,.68-.16c7.07,4.36,13.74,8.49,20.34,6.68,3.88-1.07,9-7.09,13.13-11.92,3.3-3.88,5.23-6.08,6.46-6.32,2.72-.52,7.63-2.93,8.56-4-1.33-.22-6.36.36-10.9,1.13a.5.5,0,0,1-.46-.17.49.49,0,0,1-.09-.49c.48-1.39,1.06-3.91.56-4.78a.41.41,0,0,0-.32-.24c-1.07-.22-2.82,2.76-3.66,4.84a.64.64,0,0,1-.13.19c-.4.35-9.61,8.52-14.24,10.9-4.82,2.48-16.52-5.25-17-5.58a.5.5,0,0,1,.55-.83c3.24,2.16,12.43,7.36,16,5.52,4.29-2.2,13-9.83,13.94-10.68.5-1.2,2.55-5.78,4.75-5.35a1.43,1.43,0,0,1,1,.74c.67,1.17.13,3.55-.24,4.81,4.13-.68,10.3-1.52,11.19-.68a.72.72,0,0,1,.21.57c-.19,1.66-7.09,4.59-9.52,5.05-.94.18-3.45,3.13-5.88,6-4.21,4.93-9.44,11.08-13.62,12.23A13.41,13.41,0,0,1,111.54,154.32Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M92.48,163.84c1,6.91,1.21,9.63,11.06,66.18,0,0-23,5.41-31.88,7-6-12.37-8.71-23.5-12.8-36.59-2.65-8.49-4.66-19.86-.85-27.89.61-1.28,6.49-13.75,6.49-13.75S92.52,164.09,92.48,163.84Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M72.4,132.42c.35-7.55-1.17-22.86-1.17-22.86s9.62.55,12.85-4.07c4,3.1,4.78,11.84,4,14.46-1.43,5.16-8.13,4-8.13,4l.85,9.44Z'
                    fill='#fff'
                ></path>
                <path
                    d='M80.85,133.91h0l-8.46-1a.5.5,0,0,1-.44-.52c.35-7.42-1.15-22.63-1.17-22.78a.53.53,0,0,1,.14-.4.52.52,0,0,1,.39-.15c.09,0,9.38.48,12.41-3.86a.5.5,0,0,1,.72-.11c4.15,3.25,5,12.22,4.23,15-1.27,4.54-6.28,4.59-8.07,4.45l.8,8.84a.46.46,0,0,1-.15.4A.5.5,0,0,1,80.85,133.91ZM72.92,132l7.38.87L79.5,124a.48.48,0,0,1,.16-.42.49.49,0,0,1,.42-.12c.26,0,6.28,1,7.57-3.67.62-2.22,0-10.32-3.49-13.6-3.13,3.75-10.27,3.9-12.38,3.87C72.05,112.86,73.16,125.19,72.92,132Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M80,124a8.36,8.36,0,0,1-4.15-1.72s1.83,4.58,4.65,5.26Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M80.5,128h-.11c-3-.74-4.88-5.34-5-5.54a.46.46,0,0,1,.17-.55.47.47,0,0,1,.58.06c1.24,1.25,3.85,1.58,3.87,1.59a.45.45,0,0,1,.4.39l.5,3.54a.43.43,0,0,1-.14.4A.47.47,0,0,1,80.5,128ZM77,123.58a8.19,8.19,0,0,0,2.92,3.24l-.35-2.45A10.36,10.36,0,0,1,77,123.58Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M87.25,128.53l22,12-5.31,15.37s-27.61-14.61-27.8-18.57S87.25,128.53,87.25,128.53Z'
                    fill={themeContext.secondary}
                ></path>
                <path
                    d='M92.82,166.55a73.41,73.41,0,0,0,.33-23.45c-.84-5.08-.51-8.65-2.2-11.4-5.65-9.23-27.67-6.48-30.87-.07s2.77,29.81,2.77,29.81Z'
                    fill={themeContext.secondary}
                ></path>
                <path
                    d='M72.8,114.93a3.32,3.32,0,0,0-2.34-2.52,2,2,0,0,0-1.59.18,2.14,2.14,0,0,0-.72,2,4.53,4.53,0,0,0,1.45,3,4.48,4.48,0,0,0,3.1,1.18'
                    fill='#fff'
                ></path>
                <path
                    d='M72.64,119.21a5,5,0,0,1-3.38-1.31,5.07,5.07,0,0,1-1.61-3.32,2.58,2.58,0,0,1,.94-2.4,2.42,2.42,0,0,1,2-.25,3.82,3.82,0,0,1,2.7,2.9.51.51,0,0,1-.4.59.5.5,0,0,1-.58-.4,2.83,2.83,0,0,0-2-2.12,1.42,1.42,0,0,0-1.17.1,1.73,1.73,0,0,0-.51,1.5,4,4,0,0,0,1.28,2.66,3.94,3.94,0,0,0,2.76,1h0a.51.51,0,0,1,.5.5.5.5,0,0,1-.5.5Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <ellipse
                    cx='84.39'
                    cy='110.41'
                    rx='0.46'
                    ry='0.86'
                    transform='translate(-29.06 30.15) rotate(-17.33)'
                    fill={cssRules.blackValue(themeContext)}
                ></ellipse>
                <path
                    d='M86.43,120.32a6.74,6.74,0,0,1-1.94.85,2.67,2.67,0,0,1-2.68-.47,3.11,3.11,0,0,1-.57-1.08'
                    fill='#fff'
                ></path>
                <path
                    d='M83.43,121.82a2.58,2.58,0,0,1-2-.78,3.55,3.55,0,0,1-.67-1.25.49.49,0,0,1,.29-.64.5.5,0,0,1,.65.29,2.72,2.72,0,0,0,.47.93c.61.67,1.83.42,2.19.32a6.52,6.52,0,0,0,1.79-.79.5.5,0,0,1,.69.15.49.49,0,0,1-.15.69,7.46,7.46,0,0,1-2.08.92A4.71,4.71,0,0,1,83.43,121.82Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M86.49,111.29s3.86,4.4,2.91,5.35-3.18.48-3.18.48'
                    fill='#fff'
                ></path>
                <path
                    d='M87.45,117.74a7.53,7.53,0,0,1-1.34-.13.5.5,0,0,1-.38-.6.49.49,0,0,1,.59-.38c.55.11,2.11.28,2.73-.34.21-.44-1.19-2.67-2.94-4.67a.51.51,0,0,1,0-.71.49.49,0,0,1,.7,0c1.56,1.77,4,4.92,2.9,6A3.31,3.31,0,0,1,87.45,117.74Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M103.9,156.44a.53.53,0,0,1-.24-.06c-.15-.08-15.33-8.19-20.87-11.2a.49.49,0,0,1-.2-.67.5.5,0,0,1,.68-.21c5.53,3,20.71,11.11,20.86,11.2a.49.49,0,0,1,.21.67A.5.5,0,0,1,103.9,156.44Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M293,163.33l1.85-.05a4.1,4.1,0,0,1,3.86,2.31l36.09,99.71c.48,1.32-.48,2.43-2.14,2.48l-1.84.05a4.14,4.14,0,0,1-3.87-2.31L290.89,165.8C290.42,164.48,291.37,163.37,293,163.33Z'
                    fill='#fff'
                ></path>
                <path
                    d='M330.74,268.33a4.58,4.58,0,0,1-4.23-2.64L290.42,166a2.18,2.18,0,0,1,.21-2,2.91,2.91,0,0,1,2.39-1.13l1.84-.05a4.65,4.65,0,0,1,4.35,2.63l36.09,99.72a2.17,2.17,0,0,1-.21,2,2.9,2.9,0,0,1-2.38,1.13l-1.85.05Zm-37.69-104.5a1.93,1.93,0,0,0-1.59.69,1.19,1.19,0,0,0-.1,1.11l36.09,99.72a3.68,3.68,0,0,0,3.38,2l1.85-.05a2,2,0,0,0,1.59-.7,1.19,1.19,0,0,0,.09-1.11l-36.09-99.72a3.62,3.62,0,0,0-3.38-2l-1.84.05Zm0-.5h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M295,171.16l-33.52.57a3.15,3.15,0,0,1-2.93-1.75h0c-.36-1,.37-1.84,1.62-1.87l33.52-.57a3.13,3.13,0,0,1,2.93,1.75h0C297,170.29,296.28,171.13,295,171.16Z'
                    fill='#fff'
                ></path>
                <path
                    d='M261.42,172.23a3.61,3.61,0,0,1-3.32-2.08,1.76,1.76,0,0,1,.18-1.64,2.3,2.3,0,0,1,1.9-.9L293.7,167a3.57,3.57,0,0,1,3.41,2.08,1.76,1.76,0,0,1-.17,1.63,2.29,2.29,0,0,1-1.9.91h0l-33.52.57ZM293.79,168h-.06l-33.53.57a1.33,1.33,0,0,0-1.1.47.8.8,0,0,0-.06.73,2.62,2.62,0,0,0,2.45,1.42l33.52-.57a1.33,1.33,0,0,0,1.1-.47.77.77,0,0,0,.06-.73A2.63,2.63,0,0,0,293.79,168Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M300.06,185.07l-33.52.57a3.13,3.13,0,0,1-2.93-1.75h0c-.36-1,.36-1.84,1.62-1.87l33.52-.57a3.15,3.15,0,0,1,2.93,1.75h0C302,184.2,301.31,185,300.06,185.07Z'
                    fill='#fff'
                ></path>
                <path
                    d='M266.45,186.14a3.6,3.6,0,0,1-3.31-2.08,1.76,1.76,0,0,1,.17-1.63,2.3,2.3,0,0,1,1.91-.91l33.52-.57a3.63,3.63,0,0,1,3.41,2.08,1.79,1.79,0,0,1-.17,1.64,2.33,2.33,0,0,1-1.91.9h0l-33.52.57ZM298.83,182h-.07l-33.52.57a1.32,1.32,0,0,0-1.1.47.77.77,0,0,0-.06.73,2.61,2.61,0,0,0,2.45,1.42l33.52-.57a1.32,1.32,0,0,0,1.1-.47.8.8,0,0,0,.06-.73A2.65,2.65,0,0,0,298.83,182Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M305.09,199l-33.52.56a3.13,3.13,0,0,1-2.93-1.74h0c-.36-1,.37-1.84,1.62-1.88l33.52-.56a3.11,3.11,0,0,1,2.93,1.75h0C307.07,198.12,306.35,199,305.09,199Z'
                    fill='#fff'
                ></path>
                <path
                    d='M271.49,200.06a3.61,3.61,0,0,1-3.32-2.08,1.75,1.75,0,0,1,.18-1.64,2.33,2.33,0,0,1,1.9-.91l33.52-.56a3.58,3.58,0,0,1,3.41,2.08,1.76,1.76,0,0,1-.17,1.63,2.32,2.32,0,0,1-1.9.91h0l-33.52.56Zm32.37-4.19h-.06l-33.53.56a1.39,1.39,0,0,0-1.1.47.79.79,0,0,0-.05.74,2.58,2.58,0,0,0,2.44,1.41l33.52-.56a1.36,1.36,0,0,0,1.1-.47.77.77,0,0,0,.06-.73A2.63,2.63,0,0,0,303.86,195.87Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M310.13,212.9l-33.52.57a3.13,3.13,0,0,1-2.93-1.75h0c-.36-1,.36-1.84,1.62-1.87l33.52-.57a3.14,3.14,0,0,1,2.93,1.75h0C312.11,212,311.38,212.87,310.13,212.9Z'
                    fill='#fff'
                ></path>
                <path
                    d='M276.52,214a3.6,3.6,0,0,1-3.31-2.08,1.79,1.79,0,0,1,.17-1.64,2.32,2.32,0,0,1,1.91-.9l33.52-.57a3.59,3.59,0,0,1,3.41,2.08,1.76,1.76,0,0,1-.17,1.63,2.31,2.31,0,0,1-1.91.91h0l-33.52.57Zm32.38-4.19h-.07l-33.52.57a1.32,1.32,0,0,0-1.1.47.77.77,0,0,0-.06.73A2.62,2.62,0,0,0,276.6,213l33.52-.57a1.32,1.32,0,0,0,1.1-.47.8.8,0,0,0,.06-.73A2.64,2.64,0,0,0,308.9,209.78Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M315.16,226.82l-33.52.56a3.11,3.11,0,0,1-2.92-1.75h0c-.37-1,.36-1.83,1.61-1.87l33.52-.56a3.11,3.11,0,0,1,2.93,1.74h0C317.14,225.94,316.42,226.78,315.16,226.82Z'
                    fill='#fff'
                ></path>
                <path
                    d='M281.56,227.88a3.59,3.59,0,0,1-3.31-2.08,1.71,1.71,0,0,1,.17-1.63,2.3,2.3,0,0,1,1.9-.91l33.52-.56a3.58,3.58,0,0,1,3.41,2.07,1.78,1.78,0,0,1-.17,1.64,2.35,2.35,0,0,1-1.9.91h0l-33.52.56Zm32.37-4.19h-.06l-33.53.56a1.39,1.39,0,0,0-1.1.47.8.8,0,0,0-.05.73,2.6,2.6,0,0,0,2.44,1.42l33.52-.56a1.36,1.36,0,0,0,1.1-.47.79.79,0,0,0,.06-.74A2.63,2.63,0,0,0,313.93,223.69Zm1.23,3.13h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M320.2,240.73l-33.52.57a3.13,3.13,0,0,1-2.93-1.75h0c-.36-1,.36-1.84,1.62-1.87l33.52-.57a3.14,3.14,0,0,1,2.93,1.75h0C322.18,239.86,321.45,240.7,320.2,240.73Z'
                    fill='#fff'
                ></path>
                <path
                    d='M286.59,241.8a3.6,3.6,0,0,1-3.31-2.08,1.78,1.78,0,0,1,.17-1.64,2.32,2.32,0,0,1,1.91-.9l33.52-.57a3.61,3.61,0,0,1,3.41,2.08,1.76,1.76,0,0,1-.17,1.63,2.31,2.31,0,0,1-1.91.91h0l-33.52.57ZM319,237.61h-.07l-33.52.57a1.32,1.32,0,0,0-1.1.47.77.77,0,0,0-.06.73,2.61,2.61,0,0,0,2.45,1.42l33.52-.57a1.34,1.34,0,0,0,1.1-.47.8.8,0,0,0,.06-.73A2.64,2.64,0,0,0,319,237.61Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M325.23,254.64l-33.51.57a3.13,3.13,0,0,1-2.93-1.75h0c-.37-1,.36-1.84,1.61-1.87l33.52-.57a3.13,3.13,0,0,1,2.93,1.75h0C327.21,253.77,326.49,254.61,325.23,254.64Z'
                    fill='#fff'
                ></path>
                <path
                    d='M291.63,255.71a3.59,3.59,0,0,1-3.31-2.08,1.73,1.73,0,0,1,.17-1.63,2.28,2.28,0,0,1,1.9-.91l33.52-.57a3.6,3.6,0,0,1,3.41,2.08,1.78,1.78,0,0,1-.17,1.64,2.31,2.31,0,0,1-1.9.9h0l-33.52.57ZM324,251.52h-.06l-33.53.57a1.33,1.33,0,0,0-1.1.47.8.8,0,0,0-.05.73,2.59,2.59,0,0,0,2.44,1.42l33.52-.57a1.3,1.3,0,0,0,1.1-.47.77.77,0,0,0,.06-.73A2.63,2.63,0,0,0,324,251.52Zm1.23,3.12h0Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M256.91,164l1.84-.06a4.14,4.14,0,0,1,3.87,2.31l36.09,99.72c.47,1.32-.48,2.43-2.14,2.47l-1.85,0a4.1,4.1,0,0,1-3.86-2.3l-36.09-99.72C254.29,165.12,255.25,164,256.91,164Z'
                    fill='#fff'
                ></path>
                <path
                    d='M294.62,269a4.58,4.58,0,0,1-4.23-2.63L254.3,166.61a2.17,2.17,0,0,1,.21-2,2.89,2.89,0,0,1,2.38-1.12h0l1.85-.05a4.57,4.57,0,0,1,4.35,2.63l36.09,99.72a2.18,2.18,0,0,1-.21,2,2.91,2.91,0,0,1-2.39,1.13l-1.84,0Zm-37.7-104.49a1.91,1.91,0,0,0-1.58.69,1.16,1.16,0,0,0-.1,1.11L291.33,266a3.63,3.63,0,0,0,3.38,2l1.84,0a1.92,1.92,0,0,0,1.59-.69,1.19,1.19,0,0,0,.1-1.11l-36.09-99.72a3.61,3.61,0,0,0-3.38-2Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M282.11,105.37c-7.19,4.17-15.51,7.43-23.95,5.79C251,109.78,244.54,105,242,98.08c-.51-1.36-.83-2.79-1.37-4.15-.22-.54-.56-1.14-1.14-1.19-.94-.08-1.26,1.22-1.15,2.15.09.75.18,1.44.2,2.18,0,.51,0,1.84.45,2.22l-6.4-6.2a2.51,2.51,0,0,0-1.1-.73.86.86,0,0,0-1.06.55,1.24,1.24,0,0,0,.38,1l2.55,3.29a44.41,44.41,0,0,0-3.53-2.74c-1.64-1.13-2.15.53-1.18,1.78l2.59,3.33a23.44,23.44,0,0,0-2.76-2.42c-.29-.22-.68-.43-1-.23a.6.6,0,0,0-.17.66,1.66,1.66,0,0,0,.4.61l2.41,2.75c-.42-.42-2.5-2.38-3.06-1.31-.32.61.87,1.58,1.23,1.94,2.35,2.3,4.73,4.5,7.16,6.67,6.53,5.84,14,11.25,22.81,13.24,9.65,2.19,20.25-.3,28.38-5.87'
                    fill='#fff'
                ></path>
                <path
                    d='M265.43,122.72a33,33,0,0,1-7.31-.8c-9.74-2.21-17.81-8.68-23-13.35-2.69-2.41-5-4.6-7.16-6.69l-.17-.15c-.61-.57-1.62-1.51-1.16-2.38a1,1,0,0,1,.77-.57l.2,0-.26-.29a2.33,2.33,0,0,1-.5-.79,1.1,1.1,0,0,1,.37-1.22,1,1,0,0,1,.93-.11,2,2,0,0,1-.13-2.46c.18-.19.86-.74,2.09.11l.38.27-.08-.1a1.77,1.77,0,0,1-.47-1.35,1.16,1.16,0,0,1,.52-.75,1.47,1.47,0,0,1,1.14-.2,3,3,0,0,1,1.33.86l5.14,5,0-.61a17.41,17.41,0,0,0-.15-1.76l-.05-.38a3,3,0,0,1,.61-2.3,1.32,1.32,0,0,1,1.08-.41c.66.06,1.19.57,1.56,1.51.3.74.53,1.51.76,2.25s.39,1.28.62,1.91c2.41,6.44,8.48,11.33,15.84,12.76,6.91,1.35,14.63-.53,23.6-5.73a.5.5,0,1,1,.5.86c-9.19,5.33-17.13,7.24-24.29,5.85-7.7-1.49-14.06-6.63-16.59-13.39-.24-.65-.44-1.31-.64-2s-.45-1.47-.73-2.17c-.15-.39-.4-.85-.72-.88a.33.33,0,0,0-.28.11,2.07,2.07,0,0,0-.32,1.49l0,.37c.07.62.14,1.21.16,1.84,0,1.51.24,1.82.29,1.88a.48.48,0,0,1,0,.7.49.49,0,0,1-.7,0h0l-6.4-6.19a2.24,2.24,0,0,0-.86-.61.56.56,0,0,0-.38.07.16.16,0,0,0-.08.1c0,.14.18.41.28.54l2.56,3.3a.5.5,0,0,1-.72.68,41.87,41.87,0,0,0-3.5-2.71c-.41-.28-.7-.33-.79-.24s-.16.72.29,1.3l2.59,3.33a.5.5,0,0,1-.06.69.51.51,0,0,1-.69,0,23.25,23.25,0,0,0-2.7-2.37.9.9,0,0,0-.4-.21s0,0,0,.1a1.45,1.45,0,0,0,.29.42l2.42,2.75a.5.5,0,0,1,0,.69.51.51,0,0,1-.7,0c-1-1-1.92-1.52-2.22-1.47-.06.13,0,.42.91,1.22l.18.18c2.12,2.08,4.46,4.25,7.14,6.65,5.14,4.6,13.08,11,22.59,13.12,9.19,2.09,19.65-.08,28-5.79a.5.5,0,1,1,.57.82A38.43,38.43,0,0,1,265.43,122.72Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M315.5,215.17l.9,6.59s-18.34,1.9-17.63.32a68.46,68.46,0,0,1,4.42-6.62'
                    fill='#fff'
                ></path>
                <path
                    d='M302.64,223.2c-2.27,0-3.93-.17-4.26-.64a.68.68,0,0,1-.07-.69,68.38,68.38,0,0,1,4.48-6.71.51.51,0,0,1,.7-.1.5.5,0,0,1,.1.7c0,.05-3.33,4.51-4.24,6.26,1.52.46,9.26,0,16.49-.7l-.84-6.09a.5.5,0,0,1,1-.13l.91,6.59a.51.51,0,0,1-.1.38.53.53,0,0,1-.35.19C312.42,222.68,306.59,223.2,302.64,223.2Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M289.8,189.89s3.83,4.81,3.13,5.69-21,1.88-20.13-.23,6.31-6.6,6.31-6.6'
                    fill='#fff'
                ></path>
                <path
                    d='M280.79,197.11c-3.67,0-7-.23-8-.83a.87.87,0,0,1-.46-1.13c.91-2.2,6.22-6.6,6.45-6.79a.5.5,0,0,1,.7.07.5.5,0,0,1-.07.71c-1.45,1.19-5.31,4.61-6.11,6.29,1.56,1.14,17.35.59,19.21-.18,0-.63-1.38-2.87-3.11-5.05a.49.49,0,0,1,.07-.7.51.51,0,0,1,.71.08c1.86,2.34,3.91,5.33,3.13,6.31C292.78,196.57,286.36,197.11,280.79,197.11Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M282.68,138.39s-22.91,14.22-22.62,21.91c.19,5.22,16.22,31.44,16.22,31.44l15.37-.85s-14-21.39-10.91-24.52S301,154.61,301,154.61l.31,61.49S317,215,317,214.11,320.39,141,320.39,141Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M315.91,72.3a4.15,4.15,0,0,0-1.13-2.55,2,2,0,0,0-2.58-.15c.12-1.7-1.42-3-2.92-3.85a19.62,19.62,0,0,0-6-2.12,27,27,0,0,0-4.2-.35c-2.11,0-4.36.13-6,1.42a6.73,6.73,0,0,0-2,6.87,13.66,13.66,0,0,0,4,6.32,19,19,0,0,0,7.74,4.92,20.38,20.38,0,0,0,5.38.76c2.91.1,4.17-1.27,5.66-3.49A10.87,10.87,0,0,0,315.91,72.3Z'
                    fill='#fff'
                ></path>
                <path
                    d='M308.4,84.08h-.29a21.25,21.25,0,0,1-5.51-.78,19.56,19.56,0,0,1-8-5,14.12,14.12,0,0,1-4.14-6.56,7.24,7.24,0,0,1,2.2-7.39c1.82-1.4,4.23-1.56,6.35-1.52a26.31,26.31,0,0,1,4.27.36,19.93,19.93,0,0,1,6.19,2.17c1.08.59,2.77,1.77,3.12,3.47a2.66,2.66,0,0,1,2.48.6,4.64,4.64,0,0,1,1.29,2.85h0c.39,2.71-.32,5.29-2.22,8.12C312.78,82.46,311.45,84.08,308.4,84.08Zm-9.83-20.31a8.48,8.48,0,0,0-5.24,1.32c-2,1.53-2.35,4.3-1.85,6.36a13.25,13.25,0,0,0,3.87,6.09,18.49,18.49,0,0,0,7.54,4.79,19.7,19.7,0,0,0,5.25.74c2.52.09,3.66-.94,5.22-3.27a10.29,10.29,0,0,0,2.06-7.43h0a3.61,3.61,0,0,0-1-2.25,1.49,1.49,0,0,0-1.9-.16.47.47,0,0,1-.55.09.49.49,0,0,1-.29-.48c.11-1.61-1.62-2.81-2.66-3.38a18.83,18.83,0,0,0-5.88-2.07,25.65,25.65,0,0,0-4.12-.34Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M308.88,99.45c-.35-7.55,1.18-22.86,1.18-22.86s-9.62.55-12.86-4.08c-4,3.11-4.77,11.85-4.05,14.46,1.44,5.17,8.14,4,8.14,4l-.86,9.44Z'
                    fill='#fff'
                ></path>
                <path
                    d='M300.43,100.94a.52.52,0,0,1-.35-.14.54.54,0,0,1-.15-.4l.81-8.84c-1.79.14-6.81.09-8.07-4.45-.77-2.77.08-11.74,4.22-15a.54.54,0,0,1,.39-.1.48.48,0,0,1,.33.21c3,4.34,12.32,3.86,12.42,3.86a.47.47,0,0,1,.39.15.49.49,0,0,1,.13.4c0,.15-1.51,15.36-1.17,22.78a.49.49,0,0,1-.44.52l-8.45,1Zm.86-10.44a.51.51,0,0,1,.34.13.53.53,0,0,1,.16.41l-.8,8.83,7.38-.87c-.24-6.78.87-19.11,1.13-21.89-2.09,0-9.24-.12-12.37-3.87-3.52,3.27-4.11,11.38-3.5,13.6,1.3,4.66,7.32,3.71,7.58,3.67Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M301.29,91s2.77-.35,4.14-1.72c0,0-1.83,4.58-4.65,5.26Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M300.78,95a.42.42,0,0,1-.3-.12.46.46,0,0,1-.15-.4l.51-3.55a.45.45,0,0,1,.39-.38s2.64-.35,3.88-1.59a.47.47,0,0,1,.58-.06.46.46,0,0,1,.17.55c-.08.2-1.95,4.8-5,5.53Zm.92-3.6-.36,2.45a8.21,8.21,0,0,0,2.93-3.24A10.81,10.81,0,0,1,301.7,91.4Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M295.3,94.57l-21.95,12L278.66,122s27.61-14.61,27.79-18.56S295.3,94.57,295.3,94.57Z'
                    fill={themeContext.secondary}
                ></path>
                <path
                    d='M282.11,138.81s1.05-15.71,3-27.68c.84-5.09.52-8.65,2.21-11.4,5.65-9.24,27.67-6.48,30.87-.07S320.39,141,320.39,141Z'
                    fill={themeContext.secondary}
                ></path>
                <path
                    d='M308.49,82a3.3,3.3,0,0,1,2.34-2.51,1.93,1.93,0,0,1,1.58.18,2.14,2.14,0,0,1,.73,1.95,4.5,4.5,0,0,1-4.55,4.17'
                    fill='#fff'
                ></path>
                <path
                    d='M308.65,86.24h-.07a.5.5,0,0,1-.49-.51.49.49,0,0,1,.51-.49,3.84,3.84,0,0,0,2.75-1,4,4,0,0,0,1.29-2.66,1.76,1.76,0,0,0-.51-1.5,1.43,1.43,0,0,0-1.17-.1,2.81,2.81,0,0,0-2,2.12.51.51,0,0,1-.58.4.51.51,0,0,1-.4-.59,3.8,3.8,0,0,1,2.7-2.9,2.38,2.38,0,0,1,2,.25,2.55,2.55,0,0,1,.93,2.39,5,5,0,0,1-5,4.64Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <ellipse
                    cx='296.9'
                    cy='77.43'
                    rx='0.86'
                    ry='0.46'
                    transform='translate(134.53 337.79) rotate(-72.67)'
                    fill={cssRules.blackValue(themeContext)}
                ></ellipse>
                <path
                    d='M294.85,87.35a7,7,0,0,0,1.94.85,2.69,2.69,0,0,0,2.69-.47,3.49,3.49,0,0,0,.57-1.08'
                    fill='#fff'
                ></path>
                <path
                    d='M297.86,88.85a4.72,4.72,0,0,1-1.2-.16,7.73,7.73,0,0,1-2.08-.92.5.5,0,0,1-.15-.69.49.49,0,0,1,.69-.15,6.58,6.58,0,0,0,1.8.79c.36.09,1.58.35,2.19-.32a3.12,3.12,0,0,0,.47-.93.5.5,0,0,1,.64-.29.5.5,0,0,1,.3.64,3.72,3.72,0,0,1-.67,1.25A2.63,2.63,0,0,1,297.86,88.85Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M294.8,78.32s-3.86,4.4-2.92,5.35,3.19.47,3.19.47'
                    fill='#fff'
                ></path>
                <path
                    d='M293.84,84.77a3.26,3.26,0,0,1-2.31-.75c-1.11-1.11,1.34-4.26,2.89-6a.51.51,0,0,1,.71,0,.5.5,0,0,1,0,.71c-1.75,2-3.14,4.23-2.92,4.68.61.6,2.17.44,2.72.33a.5.5,0,1,1,.2,1A6.21,6.21,0,0,1,293.84,84.77Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <path
                    d='M278.66,122.47a.49.49,0,0,1-.44-.26.51.51,0,0,1,.2-.68c.15-.08,15.34-8.19,20.87-11.19a.49.49,0,0,1,.67.2.49.49,0,0,1-.2.68c-5.53,3-20.71,11.11-20.87,11.19A.46.46,0,0,1,278.66,122.47Z'
                    fill={cssRules.blackValue(themeContext)}
                ></path>
                <line
                    x1='183.24'
                    y1='166.22'
                    x2='144.57'
                    y2='197.94'
                    fill='#fff'
                ></line>
                <path
                    d='M144.57,198.4a.46.46,0,0,1-.35-.17.46.46,0,0,1,.06-.65L183,165.86a.47.47,0,0,1,.65.06.46.46,0,0,1-.07.65l-38.66,31.72A.48.48,0,0,1,144.57,198.4Z'
                    fill='#d1d3d4'
                ></path>
                <line
                    x1='144.57'
                    y1='166.22'
                    x2='183.24'
                    y2='197.94'
                    fill='#fff'
                ></line>
                <path
                    d='M183.24,198.4a.46.46,0,0,1-.29-.11l-38.67-31.72a.46.46,0,0,1-.06-.65.47.47,0,0,1,.65-.06l38.66,31.72a.46.46,0,0,1,.07.65A.48.48,0,0,1,183.24,198.4Z'
                    fill='#d1d3d4'
                ></path>
                <path
                    d='M301,155.1a.49.49,0,0,1-.24-.93c.06,0,5.27-2.81,6.59-5.32a.49.49,0,0,1,.67-.2.49.49,0,0,1,.21.67c-1.46,2.78-6.78,5.61-7,5.73A.64.64,0,0,1,301,155.1Z'
                    fill='#fff'
                ></path>
                <path
                    d='M74.56,212.23a.49.49,0,0,1-.44-.26c-1.93-3.58-8.06-11.68-8.12-11.76a.5.5,0,1,1,.79-.6c.25.33,6.24,8.24,8.21,11.89a.49.49,0,0,1-.21.67A.46.46,0,0,1,74.56,212.23Z'
                    fill='#fff'
                ></path>
            </g>
        </svg>
    )
}

export default BuildSite
