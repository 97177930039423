import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, ThemesNames, cssRules } from 'rds'
import './index.css'
import 'rds/dist/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const myTheme = {
    main: cssRules.COLOR_PRIMARY_DEFAULT,
    secondary: cssRules.COLOR_SECONDARY_DEFAULT,
    scheme: ThemesNames.LIGHT,
    borderWidth: 1,
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={myTheme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
