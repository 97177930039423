export const PRO_PLAN_FEATURES = Object.freeze([
    'Control de inventario',
    'Control de ventas',
    'Usuarios ilimitados',
    'Constructor de tienda',
    'Locales ilimitados',
    'Reportes',
    'Soporte personalizado',
])

export const PLUS_PLAN_FEATURES = Object.freeze([
    ...PRO_PLAN_FEATURES,
    'Punto de venta',
])

export const PLANS = Object.freeze([
    {
        name: 'Pro',
        description: 'Todas las funcionalidades de reango, al mejor precio.',
        price: 30,
        features: PRO_PLAN_FEATURES,
    },
    {
        name: 'Plus',
        description: 'Para negocios en crecimiento que necesitan más.',
        price: 40,
        features: PLUS_PLAN_FEATURES,
    },
])
