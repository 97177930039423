export const PRODUCTS = Object.freeze([
    {
        slug: 'admin-panel',
        title: 'Panel de administración',
        description:
            'Administra tus productos, pedidos y clientes desde un solo lugar.',
        features: [
            {
                title: 'Gestiona tus ventas',
                highlights: [
                    'Visualiza tus ventas en tiempo real',
                    'Administra tus pedidos y clientes',
                    'Crea y edita productos fácilmente',
                ],
                preview: '/products/admin-panel-sales.png',
            },
            {
                title: 'Reportes y estadísticas',
                highlights: [
                    'Visualiza tu progreso',
                    'Identifica tus productos más vendidos',
                    'Conoce a tus clientes y sus hábitos de compra',
                ],
                preview: '/products/admin-panel-reports.png',
            },
        ],
    },
    {
        slug: 'webstore',
        title: (
            <>
                Tienda
                <br />
                Online
            </>
        ),
        description:
            'Muestra tus productos y comienza a vender en todo el país.',
    },
    {
        slug: 'pos',
        title: (
            <>
                Punto
                <br />
                de Venta
            </>
        ),
        description: 'Vende en tu local directo desde una app fácil de usar.',
    },
])
